import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { withAuth } from '@cdk-prod/fortellis-auth-context';
import { Footer } from '@fortellis/footer';
import './footer.scss';

import config from '../../config/config';
function AuthFooter({ auth, mobile }) {
  return (
    <Footer
      mobile={mobile}
      authenticated={auth.isAuthenticated}
      items={config.footer}
      routerLink={Link}
      signup={config.signup.url}
    />
  );
}

AuthFooter.propTypes = {
  auth: PropTypes.object.isRequired,
  mobile: PropTypes.bool
};

AuthFooter.defaultProps = {
  mobile: false
};

export default withRouter(withAuth(AuthFooter));

import * as amplitude from '@amplitude/analytics-browser';

export const initAmplitude = () => {
  try {
    amplitude.init(process.env.REACT_APP_AMPLITUDE);
  } catch (error) {
    console.error({
      Error: error
    });
  }
};

export const sendAmplitudeData = (eventType, eventProperties) => {
  try {
    amplitude.track(eventType, eventProperties);
  } catch (error) {
    console.error({
      Error: error
    });
  }
};

export const setAmplitudeUserProperties = (properties, value) => {
  try {
    const identifyEvent = new amplitude.Identify();
    identifyEvent.setOnce(properties, value);
    amplitude.identify(identifyEvent);
  } catch (error) {
    console.error({
      Error: error
    });
  }
};
